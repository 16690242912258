import React from "react"
import styled from "styled-components"
import { Container } from "@material-ui/core"

const MyContainer = styled(Container)`
  position: relative;
  z-index: 1;
  max-width: 100%;

  @media (min-width: 600px) {
    max-width: 90%;
    padding-left: 24px;
    padding-right: 24px;
  }    
  @media (min-width: 1366px) {
    max-width: 85%;
    padding-left: 32px;
    padding-right: 32px;
  }
`;

export default MyContainer
