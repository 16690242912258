/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./header"
import "./layout.css"
import { styles } from "./styles"

const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 50px);
  //padding-top: 115px;
`

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${styles.colorBlack};
  padding: 15px;
`;

const Text = styled.p`
  color: #fff;
  margin: 0;
`;

const Author = styled.a`
  color: ${styles.colorPrimary};
  text-decoration: none;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Main>{children}</Main>
      <Footer>
        <Text>
          © {new Date().getFullYear()}, ALL RIGHTS RESERVED. DESIGNED & CODED BY 
          {` `}
          <Author href="https://www.facebook.com/webscris" target="_blank">CRIS</Author>
        </Text>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
