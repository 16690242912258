import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { styles, Title1 } from "./styles"
import Menu from "./menu"
import Icon from "../images/logo_szs.inline.svg"
import Container from "../components/container";
import CssGrid from "../components/grid";
import { transparentize } from "polished"
import {graphql, useStaticQuery} from "gatsby";

const bgColor = transparentize(0.09, styles.colorBlack);
const yellowWrapperColor = transparentize(0.09, styles.colorPrimary);

const Wrapper = styled.header`
    background: ${bgColor};
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    padding: 24px 0;
  `;

const MenuBG = styled.div`
    min-height: 67px;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    background-color: #363533;
`;

const TranspYellowWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 100px;
    height: 115px;
    width: 29%;
    min-width: 285px;
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;

    @media (min-width: 576px) {
        min-width: 350px;
    }

    @media (min-width: 991px) {
        min-width: 370px;
    }

    @media (min-width: 1366px) {
        width: 35%;
    }
`;

const TranspYellowRectangle = styled.div`
    height: 100%;
    width: 100%;
    background: ${yellowWrapperColor};
`;

const TranspYellowTriangle = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        display: block;
        height: 100px;
        width: 100px;
        transform: translateY(-50%) rotate(45deg) skew(15deg,15deg);
        background: ${yellowWrapperColor};
        position: absolute;
        top: 50%;
        left: -50%;
    }
`;

const YellowWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 100px;
    height: 67px;
    width: 29%;
    min-width: 285px;
    position: absolute;
    z-index: 11;
    top: 24px;
    left: 0;
    bottom: 0;
    overflow: hidden;

    @media (min-width: 576px) {
        min-width: 350px;
    }

    @media (min-width: 991px) {
        min-width: 370px;
    }

    @media (min-width: 1366px) {
        width: 35%;
    }
`;

const YellowRectangle = styled.div`
    height: 100%;
    width: 100%;
    background: ${yellowWrapperColor};
`;

const YellowTriangle = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        display: block;
        height: 100px;
        width: 100px;
        transform: translateY(-50%) rotate(45deg) skew(15deg,15deg);
        background: ${styles.colorPrimary};
        position: absolute;
        top: 50%;
        left: -50%;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    height: 67px;
    width: 100%;
`;

const Content = styled.div`
    position: absolute;
    top: 24px;
    left: 0;
    width: 100%;
    z-index: 12;
`;

const ContentContainer = styled(Container)`
    position: static;
`;

const Logo = styled.div`
    height: 60px;
    width: 60px;

    @media (min-width: 1366px) {
        height: 80px;
        width: 80px;
    }
`;

const LogoText = styled.h1`
    color: ${styles.colorBlack};
    visibility: inherit;
    letter-spacing: 1px;
    font-size: 2vw;
    font-weight: normal;
    line-height: 43px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    -webkit-font-feature-settings: 'liga';
    font-feature-settings: 'liga';
    margin: 0;

    @media (min-width: 1920px) {
        font-size: 37px;
    }
`;

const TextWhite = styled.span`
    color: #FFF;
`;

const LogoIcon = styled(Icon)`
    #path1 {
        fill: ${styles.colorSecondary};
    }

    #path2 {
        fill: ${styles.colorPrimary};
        display: none;
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


const Header = () => {

    return (
        <Wrapper>
            <MenuBG />
            <TranspYellowWrapper>
                <TranspYellowRectangle />
                <TranspYellowTriangle />
            </TranspYellowWrapper>
            <YellowWrapper>
                <YellowRectangle />
                <YellowTriangle />
            </YellowWrapper>
            <Content>
                <ContentContainer>
                    <Flex>
                        <LogoContainer>
                            <Logo>
                                <LogoIcon />
                            </Logo>
                            <Title1>
                                WSZS <TextWhite>Szczecin</TextWhite>
                            </Title1>
                        </LogoContainer>
                        <Menu />
                    </Flex>
                </ContentContainer>
            </Content>
        </Wrapper>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
