import styled from "styled-components";
import {Typography} from "@material-ui/core";

export const styles = {
  colorPrimary: '#FFC722',
  colorSecondary: '#FD0100',
  colorSecondary: '#FC4445',
  colorSecondary: '#DC3741',
  colorBlack: '#363533',
  colorHover: '#1b3c69',
  colorLightGray: '#C5C6C6',
  colorGray: '#666',
};

export const SectionA = styled.section`
  padding: ${props => margin(props.p ? props.p : '26px 0')};

  @media (min-width: 576px) {
    padding: ${props => margin(props.pSM ? props.pSM : '68px 0')};
  }

  @media (min-width: 1200px) {
    padding: ${props => margin(props.pXL ? props.pXL : '110px 0')};
  }
`;

export const SectionB = styled.section`
  position: relative;

  .MuiContainer-root {
    padding: 0;

    @media (min-width: 576px) {
      padding: 0 16px;
    }
  }
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
  align-items: ${props => (props.align ? props.align : 'flex-start')};
  padding: ${props => margin(props.p ? props.p : '0')};

  @media (min-width: 992px) {
    flex-direction: ${props => (props.directionLG ? props.directionLG : props.direction)};
    justify-content: ${props => (props.justifyLG ? props.justifyLG : props.justify)};
    align-items: ${props => (props.alignLG ? props.alignLG : props.align)};
    padding: ${props => margin(props.pLG ? props.pLG : props.p)};
  }
`

// Typography

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: ${props => (props.align ? props.align : 'left')};
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};
`

export const Title1 = styled.h1`
  display: flex;
  flex-direction: column;
  color: ${styles.colorBlack};
  visibility: inherit;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: normal;
  line-height: 1.1666;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';

  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};

  @media (min-width: 576px) {
    flex-direction: row;
  }

  @media (min-width: 1366px) {
    font-size: 2vw;
  }

  @media (min-width: 1920px) {
    font-size: 37px;
  }
`

export const Title2 = styled.h2`
  font-size: 26px;
  line-height: 30px;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};
  padding: ${props => margin(props.p ? props.p : '0')};

  @media (min-width: 576px) {
    text-align: ${props => (props.alignSM ? props.alignSM : 'left')};
    padding: ${props => margin(props.pSM ? props.pSM : props.p)};
  }
`;

export const Title3 = styled.h3`
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};
  padding: ${props => margin(props.p ? props.p : '0')};

  @media (min-width: 576px) {
    text-align: ${props => (props.alignSM ? props.alignSM : 'left')};
    padding: ${props => margin(props.pSM ? props.pSM : props.p)};
  }
`

export const Title4 = styled.h4`
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};
  padding: ${props => margin(props.p ? props.p : '0')};

  @media (min-width: 576px) {
    text-align: ${props => (props.alignSM ? props.alignSM : 'left')};
    padding: ${props => margin(props.pSM ? props.pSM : props.p)};
  }
`

export const Title5 = styled.h5`
  font-size: 18px;
  line-height: 21px;
  font-weight: normal;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  position: relative;
  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};

  &::before {
      content: '';
      height: 3px;
      width: 57px;
      background-color: ${props => (props.color ? props.color : '#000')};
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
  }
`

export const Title6 = styled.h6`
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: ${props => (props.mb ? props.mb : '0')};
  ${props => margin(props.mb ? props.mb : '0')};
`

export const TitleTable = styled.h3`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};
  padding: ${props => margin(props.p ? props.p : '0')};

  @media (min-width: 576px) {
    font-size: 24px;
    line-height: 28px;
    text-align: ${props => (props.alignSM ? props.alignSM : 'left')};
    padding: ${props => margin(props.pSM ? props.pSM : props.p)};
  }

  @media (min-width: 768px) {
    color: ${props => (props.colorMD ? props.colorMD : props.color)};
  }
`

const margin = (margin) => {
  if (typeof margin === 'string') {
    return margin;
  } else if (typeof margin === 'number') {
    if (margin === 1) {
      return '3px'
    } else if (margin === 2) {
      return '8px'
    } else if (margin === 3) {
      return '15px'
    } else if (margin === 4) {
      return '30px'
    } else if (margin === 5) {
      return '48px'
    } else if (margin === 6) {
      return '72px'
    } else {
      return '0'
    }
  } else {
    return '0'
  }
}