import React from "react"
import styled from "styled-components"

// const propsContent = styled.section(props => ({
//   gridTemplateColumns: getTemplateColumns(props.columns),
//     //gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
//     `(min-width:600px) matches {
//       gridTemplateColumns: getTemplateColumns(props.columns)`,
//     }
// }));

const Content = styled.div`
    display: grid;
    grid-template-columns: ${props => (getTemplateColumns(props.xs) ? props.xs : '100%')};
    grid-auto-rows: ${props => (props.size ? props.size : 'auto')};
    grid-column-gap: ${props => (props.columnGap ? props.columnGap : '30px')};
    grid-row-gap: ${props => (props.rowGap ? props.rowGap : '30px')};
    padding: ${props => (props.padding ? props.padding : '0')};

    @media (min-width: 576px) {
      grid-template-columns: ${props => getTemplateColumns(props.sm)};
      grid-auto-rows: ${props => props.sizesm};
    }

    @media (min-width: 768px) {
      grid-template-columns: ${props => getTemplateColumns(props.md)};
      grid-auto-rows: ${props => props.sizemd};
    }

    @media (min-width: 992px) {
      grid-template-columns: ${props => getTemplateColumns(props.lg)};
      grid-auto-rows: ${props => props.sizelg};
    }

    @media (min-width: 1200px) {
      grid-template-columns: ${props => getTemplateColumns(props.xl)};
      grid-auto-rows: ${props => props.sizexl};
    }
`



const Grid = ({ xs, sm, md, lg, xl, size, sizesm, sizemd, sizelg, sizexl, columnGap, rowGap, padding, children }) => {
    return (
        <>
            <Content xs={xs} sm={sm} md={md} lg={lg} xl={xl} size={size} sizesm={sizesm} sizemd={sizemd} sizelg={sizelg} sizexl={sizexl} columnGap={columnGap} rowGap={rowGap} padding={padding}>{children}</Content>
        </>
    )
}

function getTemplateColumns(columns) {
  if (columns) {
    columns = columns.toString();
    const Array = columns.split(" ");
    if (Array.length === 1) {
      return `repeat(${columns}, 1fr)`;
    } else {
      return Array.join(" ");
    }
  }
}

export default Grid
