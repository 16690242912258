import React from "react";
import styled from "styled-components";
import { styles } from "./styles";
import { Link } from "gatsby";

const Item = styled.li`
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  margin: 0;

  @media (min-width: 768px) {
    height: 100%;
  }

  &.longName {
    display: flex;

    @media (min-width: 768px) and (max-width: 1200px) {
      display: none;
    }
  }
`;

const MenuLink = styled(Link)`
    display: flex;
    align-items: center;
    height: 100%;
    width: max-content;
    font-family: 'Oswald', sans-serif;
    font-size: 8vw;
    line-height: 1.4;
    font-weight: 400;
    text-align: center;
    margin-left: .6vw;
    margin-bottom: 0;
    padding: 5px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;

    @media (min-width: 768px) {
      font-size: 16px;
    }

    @media (min-width: 1920px) {
        font-size: 18px;
        margin-left: 15px;
    }

    &:after {
        content: '';
        height: 2px;
        width: 0%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #FDCA1E;
        transition: width .3s ease;
    }

    &:hover {
        color: #FDCA1E;

        &:after {
            width: 100%;
        }

        ul {
            display: flex;
        }
    }
`;

const MenuLinkOut = styled.a`
  display: flex;
  align-items: center;
  height: 100%;
  width: max-content;
  font-family: 'Oswald', sans-serif;
  font-size: 8vw;
  line-height: 1.4;
  font-weight: 400;
  text-align: center;
  margin-left: .6vw;
  margin-bottom: 0;
  padding: 5px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 16px;
    position: relative;
  }

  @media (min-width: 1920px) {
      font-size: 18px;
      margin-left: 15px;
  }

  &:after {
      content: '';
      height: 2px;
      width: 0%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #FDCA1E;
      transition: width .3s ease;
  }

  &:hover {
      color: #FDCA1E;

      &:after {
          width: 100%;
      }

      ul {
        display: flex;
        transform: translateX(0);

        @media (min-width: 768px) {
          transform: translateX(0) translateY(100%);
        }
      }
  }
`;

const SubList = styled.ul`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 113px);
    background-color: ${styles.colorBlack};
    width: 100%;
    list-style: none;
    padding: 15px;
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    transform: translateX(-100%);
    transition: transform .3s ease;
    z-index: 1;

    @media (min-width: 768px) {
      display: none;
      height: auto;
      width: auto;
      top: auto;
      bottom: 0;
      margin: 0;
      transform: translateX(0) translateY(100%);
      padding: 15px;
    }
`;

const SubLink = styled(Link)`
  text-decoration: none;
  padding: 10px 0;

  @media (min-width: 768px) {
    height: 100%;
  }
`;

const SubItem = styled.li`
    width: 100%;
    color: #fff;
    white-space: nowrap;
    text-align: left;

    &:hover {
        color: ${styles.colorPrimary};
    }
`;


const MenuItem = ({id, title, slug, children}) => {
  const item = linkHandler(id, title, slug, children);
  return (item);
};

export default MenuItem;

const linkHandler = (id, title, slug, children) => {
  console.log(slug);
  console.log(children);

  if (title === "Home") {
    return (
      <Item>
        <MenuLink to={"/"}>
          {title}
        </MenuLink>
      </Item>
    );
  } else if (title === "Sport Szkolny") {
    return (
      <Item>
        <MenuLinkOut>
          {title}
          {getSubmenu(children)}
        </MenuLinkOut>
      </Item>
    )
  } else if (title === "Szkolny Klub Sportowy") {
    return (
      <Item className={"longName"}>
        <MenuLink to={getLink(title, slug, children)}>
            {title}
        </MenuLink>
      </Item>
    )
  } else if (title === "System Rejestracji Szkół") {
    return (
      <Item className={"longName"}>
        <MenuLinkOut href={"http://srs.szs.pl/"} target={"_blank"}>
            {title}
        </MenuLinkOut>
      </Item>
    )
  } else {
    return (
      <Item>
        <MenuLink to={getLink(title, slug, children)}>
            {title}
        </MenuLink>
      </Item>
    );
  }
};

const getSubmenu = (children) => {
  if (children) {
      return (
          <SubList>
              {children.map(subitem => (
              <SubLink to={`/${subitem.slug}/`}>
                <SubItem>
                    {subitem.title}
                </SubItem>
              </SubLink>
              ))}
          </SubList> 
      )
  }
} 

const getLink = (title, link, children) => {
  if (title === 'Kontakt') {
      return '/#kontakt'
  } else if (children) {
      return ''
  } else {
      return `/${link}`;
  }
}
