import React from "react";
import styled from "styled-components";
import {graphql, useStaticQuery, StaticQuery, Link} from "gatsby";
import { styles } from "./styles";
import MenuItem from "./menu-item";

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 67px;

    button.active ~ ul{
        transform: translateX(0);
    }
`;

const List = styled.ul`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 113px);
    background-color: ${styles.colorBlack};
    width: 100%;
    list-style: none;
    padding: 15px;
    position: absolute;
    top: 90px;
    left: 0;
    transform: translateX(-100%);
    transition: transform .3s ease;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: flex-end;
        height: 100%;
        padding: 0;
        position: static;
        transform: translateX(0);
    }
`;

const MenuLink = styled(Link)`
    height: 100%;
    text-decoration: none;
`;

const Item = styled.li`
    display: flex;
    align-items: center;
    height: 100%;
    width: max-content;
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    text-align: center;
    margin-left: .6vw;
    margin-bottom: 0;
    padding: 5px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;

    @media (min-width: 1920px) {
        font-size: 18px;
        margin-left: 15px;
    }

    &:after {
        content: '';
        height: 2px;
        width: 0%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #FDCA1E;
        transition: width .3s ease;
    }

    &:hover {
        color: #FDCA1E;

        &:after {
            width: 100%;
        }

        ul {
            display: flex;
        }
    }

  `;

const SubList = styled.ul`
    display: none;
    flex-direction: column;
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${styles.colorBlack};
    margin: 0;
    transform: translateY(100%);
    padding: 15px;
`;

const SubItem = styled.li`
    width: 100%;
    color: #fff;
    white-space: nowrap;
    padding: 5px 0;
    text-align: left;

    &:hover {
        color: ${styles.colorPrimary};
    }
`;

const Social = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    `;

const Icon = styled.li`
  font-family: 'roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-left: 15px;
  margin-bottom: 0;
  padding: 5px;

  &:hover {
        background-color: red;
    }
  `;

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  `;

const Hamburger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 50px;
  padding: 5px;
  position: relative;
  background: transparent;
  border: 0;

  &:focus {
    outline-color: ${styles.colorPrimary};
  }

  span {
    display: block;
    height: 6px;
    width: 40px;
    background-color: ${styles.colorPrimary};
    border-radius: 2px;
    position: absolute;
    transition: transform .3s ease;

    &:nth-child(1) {
      top: 5px;
    }

    &:nth-child(2) {
      top: calc(50% - 3px);
    }

    &:nth-child(3) {
      bottom: 5px;
    }
  }

  &.active {
  justify-content: center;
    padding: 2px;

    span:nth-child(1) {
      top: calc(50% - 3px);
      transform: rotate(45deg);
      transform-origin: center;
    }

    span:nth-child(2) {
      display: none;
    }

    span:nth-child(3) {
      bottom: calc(50% - 3px);
      transform: rotate(-45deg);
      transform-origin: center;
    }
  }

  @media(min-width: 768px) {
    display: none;
  }
`;

// export default menus = useStaticQuery(graphql`
//     query menuQuery {
//         allWordpressMenusMenusItems(filter: { wordpress_id: { eq: 2 }}) {
//             nodes {
//                 id
//                 items {
//                     slug
//                     title
//                     wordpress_id
//                     child_items {
//                         slug
//                         title
//                     }
//                 }
//                 wordpress_id
//             }
//         }
//     }

// `);

// wordpressWpApiMenusMenusItems(wordpress_id: {eq: 3}) {
class HomePageMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hamburgerOpen: false
        }
    }

    handleClick = (e) => {
        e.target.closest('button').classList.toggle('active');
        this.setState(prevState => ({
            hamburgerOpen: !prevState.hamburgerOpen
        }));
    };

    render() {
        const menus = this.props.menus.nodes
        return (
            <Wrapper>
                <Hamburger onClick={(e) => this.handleClick(e)}>
                    <span />
                    <span />
                    <span />
                </Hamburger>
                <List>
                    {menus.map(node => (
                        node.items.map(item => (
                        <MenuItem id={item.wordpress_id} title={item.title} slug={item.slug} children={item.child_items}/>
                        ))
                    ))}
                </List>
            </Wrapper>
        )
    }
}

//export default HomePageMenu;
export default props => (
    <StaticQuery
    query={graphql`
        query {
            menus: allWordpressMenusMenusItems(
                filter: { wordpress_id: { eq: 2 }}
            ) 
            {
                nodes {
                    id
                    items {
                        slug
                        title
                        wordpress_id
                        child_items {
                            slug
                            title
                        }
                    }
                    wordpress_id
                }
            }
        }
    `}
    render={({ menus }) => <HomePageMenu menus={menus} {...props} />}
  />

);